import { Loading } from '../../Elements//Indicators'
import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useApi } from '../../AppContext'

const getBoards = projects => {
  const boards = projects.flatMap(project =>
    project.boards.map(board => ({
      projectName: project.name,
      projectKey: project.key,
      projectId: project.id,
      id: board.id,
      name: board.name
    }))
  )
  return boards
}

const TeamLeaderboard = ({ project, boardId }) => {
  const getStatsUri = (boardId, userId = null) => {
    return userId ? `/boards/${boardId}/stats/user/${userId}` : `/boards/${boardId}/stats/sprints`
  }
  const getQueryFn = (queryKey, uri) => {
    const fullUri = `${uri}${queryKey}`
    return async () => {
      const res = await fetch(fullUri)
      const data = await res.json()
      return data
    }
  }
  const analysisApiUri = useApi().analysis
  const [teamStats, setTeamStats] = useState([])
  const [bugKingOrQueen, setBugKingOrQueen] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const {
    isLoading: isLoadingProjects,
    error: errorProjects,
    data: allProjects
  } = useQuery({
    queryKey: ['/projects'],
    staleTime: 600000,
    enabled: boardId == 0
  })

  useEffect(() => {

    const fetchStats = async (project) => {
      let userStatsPromises

      if (project && project.users) {
        userStatsPromises = project.users.map(user => {
          const query = getStatsUri(boardId, user.id)
          return getQueryFn(query, analysisApiUri)().then(stats => {
            return {
              userName: user.name,
              userId: user.id,
              stats
            }
          }).catch(error => {
            console.error(`Error fetching stats for user ${user.name}:`, error)
            return null
          })
        })
      } else {
        userStatsPromises = allProjects.flatMap(proj =>
          proj.users.map(user => {
            const firstBoard = proj.boards[0]
            const query = getStatsUri(firstBoard.id, user.id)
            return getQueryFn(query, analysisApiUri)()
              .then(statsArray => ({
                userName: user.name,
                userId: user.id,
                stats: statsArray
              }))
          })
        )
      }

      const userStats = await Promise.all(userStatsPromises)

      // Group stats by userId
      const groupedStatsMap = new Map()
      for (const userStat of userStats) {
        if (userStat) {
          const { userId, userName, stats } = userStat
          if (!groupedStatsMap.has(userId)) {
            groupedStatsMap.set(userId, { userName, userId, stats: [] })
          }
          groupedStatsMap.get(userId).stats.push(...stats)
        }
      }

      const groupedStatsArray = Array.from(groupedStatsMap.values())
      // Keep only unique sprints for each user
      const uniqueStatsArray = groupedStatsArray.map(userStat => {
        const uniqueSprints = new Map()
        const uniqueStats = []
        for (const stat of userStat.stats) {
          if (!uniqueSprints.has(stat.id)) {
            uniqueSprints.set(stat.id, stat)
            uniqueStats.push(stat)
          }
        }
        return {
          ...userStat,
          stats: uniqueStats
        }
      })

      // Calculate total stats for each user
      const finalStats = []
      for (const userStat of uniqueStatsArray) {
        const { userName, userId, stats } = userStat
        let totalStartPoints = 0
        let totalResolvedPoints = 0
        let totalStartBugs = 0
        let totalResolvedBugs = 0

        for (const stat of stats) {
          totalStartPoints += stat.stats.startPoints
          totalResolvedPoints += stat.stats.resolvedPoints
          totalStartBugs += stat.stats.startBugs
          totalResolvedBugs += stat.stats.resolvedBugs
        }

        finalStats.push({
          userName,
          userId,
          totalStartPoints,
          totalResolvedPoints,
          totalStartBugs,
          totalResolvedBugs,
          totalSprints: stats.length,
        })
      }

      const bugKingOrQueen = finalStats.reduce((max, user) => {
        return user.totalResolvedBugs > max.bugs ? { userId: user.userId, bugs: user.totalResolvedBugs } : max
      }, { userId: null, bugs: 0 })

      setBugKingOrQueen(bugKingOrQueen.userId)

      // Add coin calculation
      const statsWithAdditionalCalculations = finalStats.map(user => {
        const coinsForResolvedPoints = user.totalResolvedPoints * 2
        const coinsForResolvedBugs = user.totalResolvedBugs * (user.userId === bugKingOrQueen.userId ? 2 : 1)
        const totalCoins = coinsForResolvedPoints + coinsForResolvedBugs
        const pointsPerSprint = user.totalSprints === 0 ? 0 : (user.totalResolvedPoints / user.totalSprints).toFixed(2)

        return {
          ...user,
          totalCoins,
          pointsPerSprint
        }
      })

      const sortedStats = [...statsWithAdditionalCalculations].sort((a, b) => b.totalResolvedPoints - a.totalResolvedPoints)

      return sortedStats
    }

    setIsLoading(true)
    setTeamStats([])
    fetchStats(project)
      .then(stats => {
        if (!stats) {
          throw new Error('userStats is undefined')
        }
        setTeamStats(stats)
        setIsLoading(false)
      })
      .catch(err => {
        console.error('Error fetching team stats:', err)
        setIsLoading(false)
      })
  }, [project, allProjects])

  if (isLoading || isLoadingProjects) {
    return <Loading message={'Loading stats...'} />
  } else if (errorProjects) {
    return <Error message={errorProjects.message} />
  } else {

    const maxResolvedPoints = Math.max(...teamStats.map(stat => stat.totalResolvedPoints))
    const maxResolvedBugs = Math.max(...teamStats.map(stat => stat.totalResolvedBugs))
    const maxDeliveryRate = Math.max(...teamStats.map(stat => (stat.totalResolvedPoints / stat.totalStartPoints) * 100)).toFixed(2)
    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      User Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Coins
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Delivered Points
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Velocity
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Delivered Bugs
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {teamStats.map((teamStat, index) => {
                    const pointsPercentage = teamStat.totalStartPoints === 0 ? 'N/A' : ((teamStat.totalResolvedPoints / teamStat.totalStartPoints) * 100).toFixed(2)
                    const bugsPercentage = teamStat.totalStartBugs === 0 ? 'N/A' : ((teamStat.totalResolvedBugs / teamStat.totalStartBugs) * 100).toFixed(2)
                    const isMaxResolvedPoints = teamStat.totalResolvedPoints === maxResolvedPoints && teamStat.totalStartPoints !== 0
                    const isMaxDeliveryRate = pointsPercentage === maxDeliveryRate && teamStat.totalStartPoints !== 0

                    return (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {teamStat.userName} {isMaxResolvedPoints ? '🥇' : ''} {teamStat.userId === bugKingOrQueen ? '🐞' : ''}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          💰 {teamStat.totalCoins}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {teamStat.totalResolvedPoints} of {teamStat.totalStartPoints} ({pointsPercentage}% {isMaxDeliveryRate ? '🥇' : ''})
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {teamStat.pointsPerSprint} points per sprint
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {teamStat.totalResolvedBugs} of {teamStat.totalStartBugs} bugs ({bugsPercentage}%)
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamLeaderboard
